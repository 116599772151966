import React from 'react';

import orbitWeb from './assets/orbit-web.svg';
import orbitIpad from './assets/orbit-ipad.svg';
import orbitMobile from './assets/orbit-mobile.svg';
import planet from './assets/planet-min.png';

import './OurOrbit.scss';

const OurOrbit = () => (
  <section className="our-orbit-section" name="our-orbit">
    <div className="our-orbit-content-container">
      <h1>Em nossa órbita</h1>
      <img src={orbitMobile} className="orbit-mobile" alt="Diagrama da nossa órbita" />
      <img src={orbitIpad} className="orbit-ipad" alt="Diagrama da nossa órbita" />
      <img src={orbitWeb} className="orbit-web" alt="Diagrama da nossa órbita" />
    </div>
    <img className="our-orbit-bottom-planet" src={planet} alt="Ilustração de um planeta" />
  </section>
);

export default OurOrbit;

import React from 'react';

import './Ring.scss';

const Ring = ({
  height, width, top, left, opacity,
}) => (
  <div
    className="service-ring"
    style={{
      height,
      width,
      top,
      left,
      opacity,
    }}
  />
);

export default Ring;

import React from 'react';

import './Banner.scss';

import logo from '../../global/assets/logo-white.svg';
import blob from './assets/banner-blob.svg';
import astronaut from './assets/astronaut.png';

const Banner = () => {
  return (
    <section className="banner">
      <img src={logo} alt="Logo do GZero" className="banner-logo"/>
      <div className="banner-container">
        <div className="banner-text">
          <p className="outline">CRIAMOS</p>
          <p className="regular offset-1">FUTUROS</p>
          <p className="outline offset-2">SEM</p>
          <p className="pink offset-3">REFERÊNCIAS</p>
        </div>
        <img src={astronaut} alt="Ilustração de um astrounauta" className="banner-astronaut"/>
      </div>
      <img src={blob} alt="Ilustração de blob como background" className="banner-blob"/>
    </section>
  );
};

export default Banner;
import React from 'react';
import './Home.scss';

import Banner from '../../components/Banner';
import OurOrbit from '../../components/OurOrbit';
import Contact from '../../components/Contact';
import TiltedText from '../../components/TiltedText';
import Services from '../../components/Services';
import BrainSection from '../../components/BrainSection/BrainSection';
import Newsletter from '../../components/Newsletter';

const Home = () => (
  <main>
    <Banner />
    <BrainSection />
    <Services />
    <OurOrbit />
    <Newsletter />
    <Contact />
    <TiltedText />
  </main>
);

export default Home;

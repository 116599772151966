import React from 'react';

import './TiltedText.scss';

const TiltedText = () => (
  <div className="tilted-text-container">
    <h2 className="tilted-text">O risco de fazer sempre do mesmo jeito é maior do que o risco de tentar fazer diferente.</h2>
  </div>
);

export default TiltedText;

import React from 'react';

import brain from './assets/brain.gif';
import './BrainSection.scss';

const BrainSection = () => (
  <section className="brain-section">
    <div className="brain-container">
      <div className="brain-text-container">
        <p>
          Despertar vidas criativas para desbloquear a inovação em empresas é a nossa forma de acessar o melhor de pessoas e organizações. Reconheça as barreiras que a sua organização enfrenta e comece a desconstruir uma por uma, transformando como a sua empresa pensa, age, se comunica e entrega valor aos seus stakeholders.
        </p>
      </div>
      <div className="brain-image-container">
        <img src={brain} alt="Ilustração de um cérebro em movimento" />
      </div>
    </div>
  </section>
);

export default BrainSection;

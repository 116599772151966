import React, { useState } from 'react';

import './PlanetDot.scss';

const PlanetDot = ({
  onClick, isActive, left, top, name, index,
}) => {
  const [shouldChange, setShouldChange] = useState(false);
  return (
    <button
      onClick={() => {
        onClick();
        setShouldChange(true);
        setTimeout(() => setShouldChange(false), 300);
      }}
      aria-label="Change slide"
      type="button"
      className="service-planet-dot"
      style={{
        background: isActive || shouldChange ? '#fff' : '#8E8E8E',
        left,
        top,
      }}
    >
      <span style={index === 2 ? ({
        top: '3rem',
        margin: 'auto',
      }) : ({
        left: '3rem',
      })}
      >
        {name}
      </span>

    </button>
  );
};

export default PlanetDot;

import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';

import './Newsletter.scss';
import submitButton from './assets/send.svg';
import Input from '../Input';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [isFocusActive, setIsFocusActive] = useState(false);
  const [shouldButtonShow, setShouldButtonShow] = useState(false);
  const { addToast } = useToasts();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      form: {
        email,
      },
    };

    const headers = {
      'Content-Type': 'application/json',
    };

    const errorMessage = 'Desculpe... Houve um erro ao cadastrar o e-mail na newsletter. Tente novamente mais tarde.';
    const successMessage = 'E-mail cadastrado com sucesso! Fique atento às novidades.';

    try {
      const res = await axios.post('https://api.gravidadezero.space/api/forms/submit/newsletter?token=e83c0981f86cb6184b48e6081de27a', data, headers);

      // Error while sending
      if (res && res.status === 200) {
        addToast(successMessage, {
          appearance: 'success',
          autoDismiss: true,
        });
        return;
      }
    } catch (error) {
      console.log(error);
    }

    addToast(errorMessage, {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  useEffect(() => {
    if (!isFocusActive) {
      setTimeout(() => setShouldButtonShow(false), 500);
    } else {
      setShouldButtonShow(true);
    }
  }, [isFocusActive]);

  return (
    <section className="newsletter" name="newsletter">
      <form onSubmit={handleSubmit} className="newsletter-content-container">
        <h1>Newsletter</h1>
        <Input
          type="email"
          id="email-input-newsletter"
          label="O seu melhor email"
          value={email}
          setValue={setEmail}
          required
          setIsFocusActive={setIsFocusActive}
        />

        {shouldButtonShow && (
        <button className={`newsletter-submit-button${isFocusActive ? '' : ' newsletter-disable'}`} type="submit">
          <img src={submitButton} alt="Enviar" />
        </button>
        )}
      </form>
    </section>
  );
};

export default Newsletter;

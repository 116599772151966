import React, { useState } from 'react';
import { Link } from 'react-scroll';

import './Navbar.scss';

const Navbar = () => {
  const [opened, setOpened] = useState(false);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setOpened(!opened);
    }
  };

  return (
    <>
      <div
        className={`nav-button ${opened ? 'opened' : ''}`}
        onClick={() => setOpened(!opened)}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className="bar first" />
        <div className="bar second" />
        <div className="bar third" />
      </div>
      <nav className={`nav ${opened ? 'opened' : 'closed'}`}>
        <div className={`nav-content ${opened ? 'opened' : 'closed'}`}>
          <ul className="nav-content-list">
            <li className={`nav-item left ${opened ? 'opened' : 'closed'}`}>
              <Link
                to="services"
                smooth
                duration={800}
                className="nav-item-link"
                onClick={() => setOpened(false)}
              >
                Serviços
              </Link>
              <span className="nav-item-link fill">
                Serviços
              </span>
            </li>
            {/* <li className={`nav-item right ${opened ? 'opened' : 'closed'}`}>
              <Link
                to="why-us"
                smooth
                duration={800}
                className="nav-item-link"
                onClick={() => setOpened(false)}
              >
                Por que nós?
              </Link>
              <span className="nav-item-link fill">
                Por que nós?
              </span>
            </li> */}
            <li className={`nav-item right ${opened ? 'opened' : 'closed'}`}>
              <Link
                to="our-orbit"
                smooth
                duration={800}
                offset={140}
                className="nav-item-link"
                onClick={() => setOpened(false)}
              >
                Em nossa órbita
              </Link>
              <span className="nav-item-link fill">
                Em nossa órbita
              </span>
            </li>
            <li className={`nav-item left ${opened ? 'opened' : 'closed'}`}>
              <Link
                to="newsletter"
                smooth
                duration={800}
                className="nav-item-link"
                onClick={() => setOpened(false)}
              >
                Newsletter
              </Link>
              <span className="nav-item-link fill">
                Newsletter
              </span>
            </li>
            <li className={`nav-item right ${opened ? 'opened' : 'closed'}`}>
              <Link
                to="contact"
                smooth
                duration={800}
                className="nav-item-link"
                onClick={() => setOpened(false)}
              >
                Contato
              </Link>
              <span className="nav-item-link fill">
                Contato
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import TagManager from 'react-gtm-module';

import './global/globalStyle.scss';

import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const tagManagerArgs = {
  gtmId: 'GTM-WT95PNG',
};

TagManager.initialize(tagManagerArgs);

const App = () => (
  <ToastProvider
    autoDismiss
    autoDismissTimeout={10000}
  >
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer />
    </Router>
  </ToastProvider>
);

export default App;

import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import './Contact.scss';

import Input from '../Input';

import image1 from './assets/contact-1-min.png';
import image2 from './assets/contact-2-min.png';
import image3 from './assets/contact-3-min.png';
import image4 from './assets/contact-4-min.png';
import image5 from './assets/contact-5-min.png';

const Contact = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [how, setHow] = useState('');
  const { addToast } = useToasts();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      form: {
        name,
        email,
        company,
        phone,
        how,
      },
    };

    const headers = {
      'Content-Type': 'application/json',
    };

    const errorMessage = 'Desculpe... Houve um erro ao entrar em contato conosco. Tente novamente mais tarde, ou envie um e-mail para contato@gravidadezero.space';
    const successMessage = 'E-mail enviado com sucesso! Em breve entraremos em contato.';

    try {
      const res = await axios.post('https://api.gravidadezero.space/api/forms/submit/contact?token=e83c0981f86cb6184b48e6081de27a', data, headers);

      // Error while sending
      if (res && res.status === 200) {
        addToast(successMessage, {
          appearance: 'success',
          autoDismiss: true,
        });
        return;
      }
    } catch (error) {
      console.log(error);
    }

    addToast(errorMessage, {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  return (
    <section className="contact" name="contact">
      <div className="contact-container">
        <h1 className="contact-title">CONTATO</h1>
        <div className="contact-images">
          <div className="contact-images-group">
            <img src={image1} alt="Ilustração em formato radial" />
            <img src={image2} alt="Ilustração em formato de ondas" />
            <img src={image3} alt="Ilustração com dois círculos" />
          </div>
          <div className="contact-images-group second-group">
            <img src={image4} alt="Ilustração de caminho" />
            <img src={image5} alt="Ilustração com triângulo e círculo" />
          </div>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <Input
            type="text"
            label="O seu nome"
            id="name"
            value={name}
            setValue={setName}
            required
          />
          <Input
            type="phone"
            label="Qual número costumam te ligar?"
            id="phone"
            value={phone}
            setValue={setPhone}
          />
          <Input
            type="text"
            label="Qual a sua empresa?"
            id="company"
            value={company}
            setValue={setCompany}
          />
          <Input
            type="email"
            label="Seu melhor e-mail"
            id="email"
            value={email}
            setValue={setEmail}
            required
          />
          <Input
            type="textarea"
            label="Como podemos resolver seu problema?"
            id="how"
            value={how}
            setValue={setHow}
            required
          />
          <button type="submit" className="contact-submit">
            Enviar
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;

import React, { useState } from 'react';

import './Input.scss';

const Input = (props) => {
  const {
    type,
    label,
    id,
    value,
    setValue,
    required,
    setIsFocusActive,
  } = props;

  const [isActive, setActive] = useState(false);

  const handleFocus = () => {
    setActive(true);
    if (setIsFocusActive) {
      setIsFocusActive(true);
    }
  };

  const handleBlur = (e) => {
    if (e.target.value !== '') {
      setActive(true);
      if (setIsFocusActive) {
        setIsFocusActive(true);
      }
      return;
    }

    setActive(false);
    if (setIsFocusActive) {
      setIsFocusActive(false);
    }
  };

  return (
    <div className={`input ${type === 'textarea' ? 'textarea' : ''}`}>
      <label htmlFor={id} className={`input-label ${isActive ? 'focused' : ''} ${type === 'textarea' ? 'textarea' : ''}`}>
        <span>
          {label}
        </span>
      </label>
      {type === 'textarea'
        ? (
          <textarea
            rows="2"
            className="input-field textarea"
            id={id}
            onChange={(e) => setValue(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )
        : (
          <input
            type={type}
            className="input-field"
            id={id}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required={required}
          />
        )}
    </div>
  );
};

export default Input;

import React, { useState } from 'react';

import './Services.scss';
import logoMobile from './assets/services-moon-mobile-min.png';
import logoWeb from './assets/services-moon-min.png';
import Ring from '../Ring';
import PlanetDot from '../PlanetDot/PlanetDot';

const Services = () => {
  const servicesList = [
    {
      title: 'ECLIPSE',
      text: 'O começo da transformação existencial e digital de uma organização. O Eclipse é um momento de curta duração (entre 1h e 4h) em que os astronautas preparam uma facilitação especial baseada nas tecnologias nativas humanas, nas tecnologias à serviço da humanidade e nas habilidades necessárias para navegar na complexidade que vivemos',
    },
    {
      title: 'DEFY',
      text: 'É o início da jornada para a inovação, focada em apresentar e discutir os conteúdos que irão preparar a organização para navegar na complexidade. Essa trilha de aprendizagem traz o despertar da consciência e mostra que o que te trouxe até aqui não é o que vai te levar até lá. Na Era da Complexidade, as empresas precisam desapegar de suas certezas e enxergar as oportunidades que moram nas dúvidas. É o momento de aumentar o repertório e trazer sabedoria de maneira aprofundada para a organização como um todo.',
    },
    {
      title: 'UNLEASH',
      text: 'Através de design, tecnologia à serviço da humanidade e inteligência coletiva ajudamos empresas a acessarem o estado de gravidade zero e desbloquearem seus verdadeiros potenciais, entregando a verdadeira inovação. Com uma abordagem próxima ao cliente (high touch), entregamos modelos de negócios verdadeiramente disruptivos, com muita tecnologia (high tech) envolvida que desafiam as regras e o status quo. É a jornada completa, de ponta a ponta, desde a ativação do grande potencial das pessoas a construção de novas tecnologias à serviço da humanidade. Altamente hands-on, criamos experiências, produtos e soluções a partir dos problemas reais da organização e de seus clientes.',
    },
  ];

  const rings = [
    {
      id: 'key-1',
      height: '60%',
      width: '60%',
      top: '20%',
      left: '20%',
      opacity: 0.1,
      dot: {
        name: 'PALESTRAS (ECLIPSE)',
        left: 'calc(80% - (2.55rem) / 2)',
        top: '50%',
      },
    },
    {
      id: 'key-2',
      height: '80%',
      width: '80%',
      top: '10%',
      left: '10%',
      opacity: 0.5,
      dot: {
        name: 'DEFY',
        left: 'calc(90% - 10%)',
        top: '70%',
      },
    },
    {
      id: 'key-3',
      height: '100%',
      width: '100%',
      top: '0',
      left: '0',
      opacity: 0.1,
      dot: {
        name: 'UNLEASH',
        left: 'calc(94%)',
        top: '30%',
      },
    },
  ];

  const [activeSlide, setActiveSlide] = useState(0);
  const [sliderTransition, setSliderTransition] = useState(false);
  const [shouldNextAppear, setShouldNextAppear] = useState(true);
  const [shouldPreviousAppear, setShouldPreviousAppear] = useState(false);

  const changeSlide = ({ nextSlideIndex, action }) => {
    const delay = (nextSlide) => {
      setShouldNextAppear(nextSlide !== 2);
      setShouldPreviousAppear(nextSlide !== 0);

      setSliderTransition(true);
      setTimeout(() => {
        setActiveSlide(nextSlide);

        setSliderTransition(false);
      }, 300);
    };

    if (Number.isInteger(nextSlideIndex)) {
      if (nextSlideIndex < servicesList.length && nextSlideIndex >= 0) {
        return delay(nextSlideIndex);
      }
      return false;
    }

    if (action === 'next') {
      return delay(activeSlide + 1 !== servicesList.length ? activeSlide + 1 : 0);
    }
    if (action === 'previous') {
      return delay(activeSlide - 1 >= 0 ? activeSlide - 1 : servicesList.length - 1);
    }

    return false;
  };

  return (
    <section className="services-section" name="services">
      <div className="services-planets-dots-container">
        <div className="service-planets-dots-relative">
          <img src={logoWeb} alt="logo" />

          {rings.map((ring, index) => {
            const {
              height, width, top, left, opacity, dot,
            } = ring;

            return (
              <React.Fragment key={ring.id}>
                <Ring
                  height={height}
                  width={width}
                  top={top}
                  left={left}
                  opacity={opacity}
                />
                <PlanetDot
                  left={dot.left}
                  top={dot.top}
                  name={dot.name}
                  isActive={index === activeSlide}
                  index={index}
                  onClick={() => {
                    changeSlide({ nextSlideIndex: index });
                  }}
                />
              </React.Fragment>
            );
          })}

        </div>
      </div>
      <div className="services-content-container">
        <h1>O que fazemos</h1>

        <img src={logoMobile} alt="logo" />

        <div className={`services-slide carousel-slider-${sliderTransition ? 'fadeout' : 'fadein'}`}>
          <h3 className="services-slide-title">
            <span>{`0${activeSlide + 1}`}</span>
            {` ${servicesList[activeSlide].title}`}
          </h3>

          <p className="services-slide-text">{servicesList[activeSlide].text.toUpperCase()}</p>
        </div>

        <div className="services-carousel-buttons-container">
          <button
            type="button"
            onClick={() => changeSlide({ action: 'previous' })}
            className={shouldPreviousAppear ? '' : 'deactivate-arrow'}
          >
            PREVIOUS
          </button>
          <button
            type="button"
            onClick={() => changeSlide({ action: 'next' })}
            className={shouldNextAppear ? '' : 'deactivate-arrow'}
          >
            NEXT
          </button>
        </div>
      </div>
    </section>
  );
};

export default Services;

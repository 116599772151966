import React from 'react';

import './Footer.scss';
import background from './assets/footer-min.png';

const Footer = () => (
  <footer className="footer">
    <img src={background} alt="Ilustração de uma estrada sem fim" />
  </footer>
);

export default Footer;
